import React from "react";
import { Container, Tab, ListGroup } from "react-bootstrap";

var parsedUrl = new URL(window.location.toString());
console.log("Title shared: " + parsedUrl.searchParams.get("name"));
console.log("Text shared: " + parsedUrl.searchParams.get("description"));
console.log("URL shared: " + parsedUrl.searchParams.get("link"));

export default function () {
  function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
    console.debug(e.currentTarget);
  }

  return (
    <Container fluid className="mt-3 mb-3">
      <p>Nug it</p>
      <Tab.Container
        id="list-group-tabs-example"
        defaultActiveKey="#read-later"
      >
        <ListGroup>
          <ListGroup.Item action onClick={handleClick} href="#read-later">
            Read Later
          </ListGroup.Item>
          <ListGroup.Item action onClick={handleClick} href="#save">
            Save
          </ListGroup.Item>
          <ListGroup.Item action onClick={handleClick} href="#publish">
            Publish
          </ListGroup.Item>
        </ListGroup>
      </Tab.Container>
    </Container>
  );
}
