import React from "react";
import "./App.css";
import Bot from "./Bot";
import Nug from "./Nug";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

var parsedUrl = new URL(window.location.toString());
console.log("Title shared: " + parsedUrl.searchParams.get("name"));
console.log("Text shared: " + parsedUrl.searchParams.get("description"));
console.log("URL shared: " + parsedUrl.searchParams.get("link"));

function App() {
  function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
    console.debug(e.currentTarget);
  }

  return (
    <Router>
      <Switch>
        <Route path="/s">
          <Bot />
        </Route>
        <Route path="/">
          <Nug />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
