import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function () {
  return (
    <>
      <Navbar variant="dark" bg="dark" expand="lg">
        <Navbar.Brand as={Link} to="/">
          nugbot
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/sign-in">
              Sign In
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>{" "}
      </Navbar>
      <Container fluid className="mt-3 mb-3"></Container>
    </>
  );
}
